import styled from "styled-components"
import TopPageBanner from "../../components/top-page-banner/top-page-banner.component"
import WhiteArrow from "../../images/blue-invert-arrow.png"
import { Typography } from "@material-ui/core"
import CustomButton from "../../components/custom-button/custom-button.component"

export const TabText = styled.h2`
  cursor: pointer;
  color: white;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 56px;
  text-align: center;
  transition: all 0.3s ease;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 18px;
  }

  &:after {
    content: "";
    position: relative;
    background-color: transparent;
    transition: all 0.3s ease;
    height: 8px;
    display: block;
    margin-top: 1em;
  }

  &.active {
    &:after {
      background-color: #df272f;
    }
    color: #df272f;
  }
`

export const InventoryHero = styled(TopPageBanner)`
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    //background-color:  ${({ theme }) => theme.palette.primary.main};
    background-color: rgba(0, 0, 0, 0.4);
    &:after {
      content: "";
      height: 200px;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background: url(${WhiteArrow}) no-repeat center center;
      background-size: cover;
    }
  }
`
export const InventoryDescription = styled(Typography)`
  font-size: 20px;
  line-height: 44px;
  span {
    color: #2a334e;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 40px;
  }

  p:last-child {
    padding-top: 2em;
  }
`

export const InventoryButton = styled(CustomButton)`
  
    color: ${({ theme }) => theme.palette.secondary.main};
    background-color: transparent;
    padding: 1em 2.5em;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: 8px;
    border: 1px ${({ theme }) => theme.palette.secondary.main} solid;
    
    &:hover {
        background-color: transparent;
        color: white;
      }
   
}
`
