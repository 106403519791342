import React, { useRef, useState } from "react"
import * as S from "./hero-slider.styles"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination } from "swiper"
// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import { createTheme } from "@material-ui/core/styles"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import CustomLightbox from "../custom-lightbox/custom-lightbox.component"

SwiperCore.use([Pagination, Navigation])

const HeroSlider = ({ images }) => {
  const defaultTheme = createTheme()
  const matchesMd = useMediaQuery(defaultTheme.breakpoints.up("md"))

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)
  const [galleryIndex, setGalleryIndex] = useState(0)

  // const settingsSlider2 = {
  //   dots: false,
  //   infinite: images.length > 5,
  //   speed: 500,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   swipeToSlide: true,
  //   focusOnSelect: true,
  //   centerMode: true,
  //   centerPadding: 0,
  //   responsive: [
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         centerMode: true,
  //         infinite: true,
  //       },
  //     },
  //   ],
  // }
  //
  return (
    <div style={{ position: "relative" }}>
      <S.TopSliderWrapper>
        {matchesMd && (
          <S.CustomPrevArrow ref={navigationPrevRef}>
            <ArrowBackIcon />
          </S.CustomPrevArrow>
        )}
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          grabCursor={true}
          loop={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          pagination={!matchesMd}
        >
          {images.map((image, index) => {
            return (
              <SwiperSlide
                onClick={() => {
                  setGalleryIndex(index)
                  setIsLightBoxOpen(!matchesMd)
                }}
              >
                <S.HeroWrapper
                  key={`inventory-image-${index}`}
                  img={image.image}
                >
                  <div className="overlay" />
                </S.HeroWrapper>
              </SwiperSlide>
            )
          })}
          <CustomLightbox
            images={images}
            handleLightBoxOpen={setIsLightBoxOpen}
            galleryIndex={galleryIndex}
            handleGalleryIndex={setGalleryIndex}
            isLightBoxOpen={isLightBoxOpen}
          />
        </Swiper>
        {matchesMd && (
          <>
            <S.CustomNextArrow ref={navigationNextRef}>
              <ArrowForwardIcon />
            </S.CustomNextArrow>
          </>
        )}
      </S.TopSliderWrapper>

      {/*{matchesLg &&*/}
      {/*<S.ImagesWrapper>*/}
      {/*  <Slider*/}
      {/*    {...settingsSlider2}*/}
      {/*    asNavFor={navs.nav1}*/}
      {/*    arrows={false}*/}
      {/*    ref={c => (slider2 = c)}*/}
      {/*  >*/}
      {/*    {images.map((image, index) => {*/}
      {/*      return (*/}
      {/*        <S.SlideImageWrapper*/}
      {/*          onClick={() => {*/}
      {/*            setGalleryIndex(index)*/}
      {/*            setIsLightBoxOpen(true)*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <S.ImageSlider2*/}
      {/*            fluid={*/}
      {/*              image.image && image.image.localFile.childImageSharp.fluid*/}
      {/*            }*/}
      {/*          />*/}
      {/*        </S.SlideImageWrapper>*/}
      {/*      )*/}
      {/*    })}*/}
      {/*  </Slider>*/}
      {/*</S.ImagesWrapper>*/}

      {/*<CustomLightbox*/}
      {/*  images={images}*/}
      {/*  isLightBoxOpen={isLightBoxOpen}*/}
      {/*  handleLightBoxOpen={setIsLightBoxOpen}*/}
      {/*  handleGalleryIndex={setGalleryIndex}*/}
      {/*  galleryIndex={galleryIndex}*/}
      {/*/>*/}
    </div>
  )
}
export default HeroSlider
