import styled, { css } from "styled-components"
import WhiteArrow from "../../images/white-invert-arrow.png"
import { Container, darken, IconButton } from "@material-ui/core"
import CustomImage from "../custom-image/custom-image.component"
import CustomBgImage from "../custom-bg-image/custom-bg-image.component"

export const HeroWrapper = styled(CustomBgImage)`
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    min-height: 600px;
    height: calc(100vh - ${({ theme }) => `${theme.navHeight}px`});
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    //background-color:  ${({ theme }) => theme.palette.primary.main};
    background-color: rgba(0, 0, 0, 0.4);
  }
`

export const TopSliderWrapper = styled.div`
  &:after {
    content: "";
    height: 200px;
    width: 100%;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: url(${WhiteArrow}) no-repeat center center;
    background-size: cover;
    pointer-events: none;
  }
`

export const ImagesWrapper = styled(Container)`
  position: absolute;
  text-align: center;
  bottom: 140px;
  left: 0;
  right: 0;

  .slick-slide {
    box-sizing: border-box;
  }

  .slick-active.slick-current {
    img {
      outline: 1px solid ${({ theme }) => theme.palette.secondary.main};
      outline-offset: -1px;
    }
  }
`

export const SlideImageWrapper = styled.div`
  padding-left: 1em;
  padding-right: 1em;
  cursor: pointer;
`

export const ImageSlider2 = styled(CustomImage)`
  width: 100%;
  height: 110px;
`

const ArrowCircle = css`
  content: "";
  width: 120px;
  height: 120px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  cursor: pointer;

  .MuiSvgIcon-root {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    background-color: ${({ theme }) =>
      darken(theme.palette.secondary.main, 0.1)};
  }
`

export const CustomNextArrow = styled(IconButton)`
  right: -60px;
  z-index: 2;
  ${ArrowCircle}

  .MuiSvgIcon-root {
    left: 1em;
  }
`

export const CustomPrevArrow = styled(IconButton)`
  left: -60px;
  z-index: 2;
  ${ArrowCircle}

  .MuiSvgIcon-root {
    right: 1em;
  }
`
